import axios from '@/plugins/axios';

class AdminService {
  getAntifraudMessages(data) {
    this.data = data;
    return axios().get('admin/antifraud/messages', { params: this.data }).then((response) => response.data);
  }

  approveMessage(messageId) {
    this.messageId = messageId;
    return axios().put(`admin/antifraud/messages/${messageId}/approve`);
  }

  getAntifraudWords(data) {
    this.data = data;
    return axios().get('admin/antifraud/words', { params: this.data }).then((response) => response.data);
  }

  getMessage(id) {
    this.id = id;
    return axios().get(`sms/messages/${id}`);
  }

  sendSms(data) {
    this.data = data;
    return axios().post('sms/messages', this.data);
  }

  getCampaigns(data) {
    this.data = data;
    return axios().get('sms/campaigns', { params: this.data }).then((response) => response.data);
  }

  editCampaign(data) {
    this.data = data;
    return axios().put(`sms/campaigns/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteCampaign(campaignId) {
    this.campaignId = campaignId;
    return axios().delete(`sms/campaigns/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getInsights(data) {
    this.data = data;
    return axios().post('sms/insights', this.data).then((response) => response.data);
  }

  exportSms(data) {
    this.data = data;
    return axios().post('sms/reports', this.data);
  }

  deleteTemplates(data) {
    this.data = data;
    return axios().delete('sms/templates', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  downlodExport(id) {
    this.id = id;
    return axios().get(`sms/reports/${id}/file`);
  }

  getSmsPrice(id) {
    this.id = id;
    return axios().get(`sms/prices/${id}`);
  }

  putSmsPrice(data, id) {
    this.data = data;
    console.log(JSON.stringify(data));
    return axios().put(`sms/prices/${id}`, this.data);
  }

  reportsExport() {
    this.id = null;
    return axios().get('sms/reports');
  }

  filterSms(filter) {
    this.data = null;
    return axios().get(`sms/messages${filter}`);
  }

  chart(days) {
    this.a = 'a';
    return axios().get(`sms/messages/charts?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  carriers(days) {
    this.a = 'a';
    return axios().get(`sms/messages/carriers?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getTemplates(data, page) {
    this.data = data;
    return axios().get(`sms/templates?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  getBlacklists(data) {
    this.data = data;
    return axios().get('blacklist', { params: this.data }).then((response) => response.data);
  }

  createTemplate(data) {
    this.data = data;
    return axios().post('sms/templates', this.data).then((response) => response.data);
  }

  createBlackList(data) {
    this.data = data;
    return axios().post('/blacklist', this.data).then((response) => response.data);
  }

  createKeywords(data) {
    this.data = data;
    return axios().post('/keywords', this.data).then((response) => response.data);
  }

  deleteKeyword(id) {
    this.id = id;
    return axios().delete(`/keywords/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteMultipleKeywords(data) {
    this.data = data;
    console.log(data);
    return axios().delete('/keywords', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteKeylist(data, page) {
    this.data = data;
    return axios().get(`/keywords?page=${page}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteKeylists(data) {
    this.data = data;
    return axios().delete('/keywords', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editKeyList(data) {
    this.data = data;
    return axios().put(`/keywords/${data.id}`, this.data).then((response) => response.data);
  }

  getKeylist(data, page) {
    this.data = data;
    return axios().get(`/keywords?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  getTabulation(data, page) {
    this.data = data;
    return axios().get(`/tab?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  createTabulation(data) {
    this.data = data;
    return axios().post('/tab', this.data).then((response) => response.data);
  }

  editTabulation(data) {
    this.data = data;
    return axios().put(`/tab/${data.id}`, this.data).then((response) => response.data);
  }

  deleteTab(id) {
    this.id = id;
    return axios().delete(`/tab/${id}`).then((response) => response.data);
  }

  deleteAllTab(data) {
    this.data = data;
    return axios().delete('/tab', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  deleteMultipleTab(data) {
    this.data = data;
    return axios().delete('/tab', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteBlackList(id) {
    this.id = id;
    return axios().delete(`/blacklist/${id}`).then((response) => response.data);
  }

  fetchOptouts(data, page) {
    this.data = data;
    return axios().get(`/optouts?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  createOptouts(data) {
    this.data = data;
    return axios().post('/optouts', this.data).then((response) => response.data);
  }

  deleteOptout(id) {
    this.id = id;
    return axios().delete(`/optouts/${id}`).then((response) => response.data);
  }

  deleteAllOptouts(data) {
    this.data = data;
    return axios().delete('/optouts', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editOptouts(data) {
    this.data = data;
    return axios().put(`/optouts/${data.id}`, this.data).then((response) => response.data);
  }

  editBlackList(data) {
    this.data = data;
    return axios().put(`/blacklist/${data.id}`, this.data).then((response) => response.data);
  }

  deleteBlacklists(data) {
    this.data = data;
    return axios().delete('/blacklist', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editTemplate(data) {
    this.data = data;
    return axios().put(`sms/templates/${data.id}`, this.data).then((response) => response.data);
  }

  deleteTemplate(id) {
    this.id = id;
    return axios().delete(`sms/templates/${id}`).then((response) => response.data);
  }

  getAvailableShortCodes(data) {
    this.data = data;
    return axios().get('sms/short_codes/available', { params: this.data }).then((response) => response.data);
  }

  getShortCodes(id) {
    this.id = id;
    return axios().get(`sms/short_codes/${id}`).then((response) => response.data);
  }

  getAccountShortCodes() {
    this.id = null;
    return axios().get('sms/short_codes').then((response) => response.data);
  }
}

export default new AdminService();
