<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nova tabulação</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Nome</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.name"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              Cancelar
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '@/services/admin.service';
import Modal from '@/mixins/Modal';
import axios from '@/plugins/axios';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      form: { name: '' },
      isSending: false,
    };
  },
  methods: {
    save() {
      this.isSending = true;
      axios().post('webchat/tabs/create', this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: 'Sucesso',
            content: 'Tabulação criada com sucesso',
            type: 'success',
          });
          this.$emit('submit');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `Erro ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
          console.log(error.response.data.message);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
