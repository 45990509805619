<template>
  <div class="content">
    <PageHeader title="Tabulações" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="float-left"></div>
              <div class="float-right">
                <div class="input-group">
                  <div >
                    <button
                      v-modal="{ target: 'create-tab-modal' }"
                      class="btn btn-success"
                    >
                      <i class="icon dripicons-plus"></i>
                      <span class="btn-trim">Adicionar Nova</span>
                    </button>
                    <!-- <button
                      class="btn btn-danger btn-remove border-radius-right"
                      :class="{
                        'qt-loader qt-loader-mini qt-loader-right': isSending,
                      }"
                      @click="deleteMultiple"
                      v-bind:disabled="selectedTabs.length == 0 || isSending"
                    >
                      <i class="icon dripicons-trash color-white"></i>
                      <span class="btn-trim">Excluir</span>
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!tabs.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br>
                  <i class="fas fa-list font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhuma tabulação cadastrada</h5>
                </div>
                <table v-if="fetched" class="table">
                  <!-- <thead v-if="tabs.length">
                    <tr>
                      <th scope="col" class="text-left">Nome</th>
                      <th scope="col"></th>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr v-for="(tab, i) in tabs" :key="tab.id">
                      <td data-label="Nome: " class="text-left m-text-right">
                        <div>{{ tab.name }}</div>
                      </td>
                      <td data-label="" class="text-right">
                        <div class="dropdown" style="margin-right: 5px">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#keyword-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'keyword-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <!-- <a
                              class="dropdown-item"
                              v-modal="{ target: 'edit-tab-modal', data: tab }"
                              >Editar</a
                            > -->
                            <a class="dropdown-item" @click="remove(tab)"
                              >Remover</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="qt-block-ui relative" style="padding: 120px" />
                <br />
                <Pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <tabs-modal id="create-tab-modal" @submit="refresh()" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import TabsModal from '@/components/TabsModal.vue';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import axios from '@/plugins/axios';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    TabsModal,
    Pagination,
  },
  data() {
    return {
      isSending: false,
      fetched: false,
      paginate: {},
      tabs: [],
      selectedTabs: [],
      form: {
        page: 1,
      },
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      axios().get('webchat/tabs').then((response) => {
        this.tabs = response.data;
        console.log('webchat/tabs', response.data);
      }, (error) => {
        console.log(error);
        this.content = error;
      }).finally(() => {
        this.fetched = true;
      });
    },
    remove(tab) {
      Swal.fire({
        title: `Remover tabulação "${tab.name}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.fetched = false;

          axios().delete(`webchat/tabs/${tab.id}`).then((response) => {
            console.log('webchat/tabs', response.data);
            setTimeout(() => { this.$live.requestTabs(); }, 1);
            return this.fetch();
          }, (error) => {
            console.log(error);
            this.content = error;
          }).finally(() => {
            this.fetched = true;
          });
        }
      });
    },
    refresh() {
      if (this.$live) setTimeout(() => { this.$live.requestTabs(); }, 1);

      this.fetch(1);
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
